import "./App.css";
import Work from "./pages/Work";
import Resume from "./pages/Resume";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import solidity_backend_lacity from "./works/solidity_backend_lacity.json"
import project_development_manager_alien6 from "./works/project_development_manager_alien6.json";
import co_founder_amanu from "./works/co-founder_amanu.json";
import web_mobile_developer_alien6 from "./works/web_and_mobile_developer_alien6.json";
import ios_developer_eastechnology from "./works/ios_developer_eastechnology.json";
import netlify_like_jamstack_implementation from "./projects/netlify_like_jamstack_implementation.json";
import information_system_design from "./projects/information_system_design.json";

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<Resume />} />
          <Route path="/work/solidity_backend_lacity" element={<Work content={solidity_backend_lacity} />} />
          <Route path="/work/project_development_manager_alien6" element={<Work content={project_development_manager_alien6} />} />
          <Route path="/work/co-founder_amanu" element={<Work content={co_founder_amanu} />} />
          <Route path="/work/web_and_mobile_developer_alien6" element={<Work content={web_mobile_developer_alien6} />} />
          <Route path="/work/ios_developer_eastechnology" element={<Work content={ios_developer_eastechnology} />} />
          <Route path="/project/netlify_like_jamstack_implementation" element={<Work content={netlify_like_jamstack_implementation} />} />
          <Route path="/project/information_system_design" element={<Work content={information_system_design} />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
