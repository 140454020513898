import React from "react";
import "../sass/ResumeMain.scss";
import Item from "./Item";

import profilePicture from "../logo.jpg";

export default function ResumeMain() {
  const headerImage = React.useRef();

  return (
    <div className="resume">
      <div className="background"></div>
      <div className="content">
        <header>
          <div className="img" ref={headerImage} style={{}}>
            <img src={profilePicture} alt="" />
          </div>
          <div>
            <h1>Charles Simon-Meunier</h1>
            <h2>Sofware engineer</h2>
          </div>
        </header>
        <section>
          <h3>Work Experience</h3>
          <Item
            title="Software Engineer @ Alien6"
            startDate="2021"
            endDate="Present"
            description={
              <ul>
                <li>
                  Developing a platform to allow data scientists to monetize and
                  gain from algorithms and IA models.
                </li>
                <li>
                  Usage of blockchain to provide ownership and transparency.
                  Data scientist could sell and transfer their code with NFTs.
                </li>
                <li>
                  Developing the platform in TypeScript, with ReactJS and React
                  Native for mobile. Smart contracts are coded with Solidity,
                  deployed to EVM Blockchains.
                </li>
              </ul>
            }
            ctaContent="View work"
            ctaLink="/work/project_development_manager_alien6"
          />
          <Item
            title="Solidity & Backend Developer @ LaCity"
            startDate="2022"
            endDate="Present"
            description={
              <ul>
                <li>
                  Create a new diploma issuance process to gain traceability and
                  save more than 10 hours of work per diploma using signatures
                  in the blockchain, secured by smart contracts.
                </li>
                <li>Partnership with Binance to obtain tech support.</li>
                <li>
                  Graduation of approximately 500 students per year and project
                  to graduate more than 5,000 students per year in the coming
                  years.
                </li>
                <li>
                  Development of a big project. More than 10,000 lines of code
                  in JavaScript & TypeScript and smart contracts in Solidity for
                  EVM Blockchains.
                </li>
              </ul>
            }
            ctaContent="View work"
            ctaLink="/work/solidity_backend_lacity"
          />
          <Item
            title="Co-founder & CTO @ Amanu"
            startDate="2022"
            endDate="Present"
            description={
              <ul>
                <li>
                  Introduce artists to the benefits of the blockchain for
                  traceability of their art, the relation with their community
                  and reach a new kind of buyers using NFT.
                </li>
                <li>
                  Linking physical and digital art to provide a better and
                  longer experience to art buyers.
                </li>
                <li>
                  Giving artists the possibility to earn up to 5% of commission
                  on every secondary market resale of their work.
                </li>
              </ul>
            }
            ctaContent="View work"
            ctaLink="/work/co-founder_amanu"
          />
          <Item
            title="Web and Mobile Developer @ Alien6"
            startDate="2020"
            endDate="2021"
            description={
              <ul>
                <li>
                  Created and deployed 2 public websites for limited time
                  events.
                </li>
                <li>Updating of the company's main site.</li>
                <li>
                  Design and development of a React Native application to
                  promote articles on AI.
                </li>
              </ul>
            }
            ctaContent="View work"
            ctaLink="/work/web_and_mobile_developer_alien6"
            duration="1 year"
          />
          <Item
            title="iOS Developer @ EASTECHNOLOGY"
            startDate="2020"
            endDate="2020"
            description={
              <ul>
                <li>
                  Development of a mobile application for iOS in order to help
                  the catering sector.
                </li>
                <li>
                  Development in parallel with an Android and WEB team. Use of
                  Xcode, in Swift language
                </li>
              </ul>
            }
            ctaContent="View work"
            ctaLink="/work/ios_developer_eastechnology"
            duration="3 months"
          />
        </section>
        <hr />
        <section>
          <h3>Project Experience</h3>
          <Item
            title="Netlify like: Jamstack implementation @ EPITA"
            startDate="Jun 2023"
            endDate="July 2023"
            description={
              <ul>
                <li>
                  Implementing a Jamstack website in order to reduce the carbon
                  impact of a blog with Gitlab CI, AWS, Terraform
                </li>
                <li>
                  Resulting in no more computing power needed. Blog articles
                  stored directly on the repository and exposure of the static
                  site on an S3 bucket. Integration of an headless CMS to allow
                  editing of content by non-technical persons.
                </li>
              </ul>
            }
            ctaContent="View project"
            ctaLink="/project/netlify_like_jamstack_implementation"
          />
          <Item
            title="Information System Design @ EPITA"
            startDate="Mar 2023"
            endDate="Feb 2024"
            description={
              <ul>
                <li>
                  Managed a team of 50 students in the successful development
                  and delivery of a small information system consisting of 9
                  applications.
                </li>
                <li>
                  Identified the functional needs and applications necessary for
                  a typical Fnac/Darty company.
                </li>
                <li>
                  Oversaw quality assurance processes that resulted in a
                  near-zero defect rate across all applications, ensuring
                  seamless user experience and maximum functionality.
                </li>
              </ul>
            }
            ctaContent="View project"
            ctaLink="/project/information_system_design"
          />
        </section>
        <hr />
        <section>
          <h3>Education</h3>
          <Item
            title="M.Sc. in Computer Science at EPITA"
            startDate="2019"
            endDate="2024"
            location="Paris, France"
            description={
              <ul>
                <li>
                  Exchange program in Prague at the Czech Technical University
                  during 6 months from January to June 2021
                </li>
                <li>
                  Organizer of hackathons & conferences with LaCity, association
                  of finance & blockchain at school. In partnership with Binance
                  and XBTO.
                </li>
                <li>
                  Immersion days organizer, leader of a team of 10 students and
                  support of 30 students for the discovery of the school on an
                  afternoon
                </li>
              </ul>
            }
            grade={
              <span>
                GPA: <span className="grade">3.7/4</span>
              </span>
            }
          />
        </section>
      </div>
    </div>
  );
}
