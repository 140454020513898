import React from "react";
import { gsap } from "gsap";

import "../sass/Footer.scss";

export default function Footer() {
  return (
    <footer>
      <div className="content">
        <h3>About</h3>
        <p>
          High performing software engineer with 2 years of exeperience in Web3,
          blockchain, and project management. I have a strong understanding of
          Solidity, React Native, and backend development. I am also an
          experienced project manager with a proven track record of success in
          leading and delivering projects.
        </p>
        <h3>Skills</h3>
        <ul>
          <li>Software Architecture</li>
          <li>Cloud</li>
          <li>Containerisation</li>
          <li>Databases</li>
          <li>OOP</li>
          <li>Web development</li>
          <li>Business Case</li>
          <li>Problem-solving</li>
          <li>Leadership</li>
        </ul>
        <h3>Contact</h3>
        <a href="mailto:charles.simon-meunier@epita.fr">
          Email
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
          >
            <path
              d="M17.7,11.2C17.7,11.2,17.7,11.2,17.7,11.2l-5-5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.3,3.3H7c-0.6,0-1,0.4-1,1
	s0.4,1,1,1h7.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l5-5C18.1,12.3,18.1,11.6,17.7,11.2z"
              fill="#fff"
            />
          </svg>
        </a>
        <a
          href="https://github.com/csimonmeunier"
          target="_blank"
          rel="noreferrer"
        >
          Github
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
          >
            <path
              d="M17.7,11.2C17.7,11.2,17.7,11.2,17.7,11.2l-5-5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.3,3.3H7c-0.6,0-1,0.4-1,1
	s0.4,1,1,1h7.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l5-5C18.1,12.3,18.1,11.6,17.7,11.2z"
              fill="#fff"
            />
          </svg>
        </a>
        <a
          href="https://linkedin.com/in/csimonmeunier"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
          >
            <path
              d="M17.7,11.2C17.7,11.2,17.7,11.2,17.7,11.2l-5-5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.3,3.3H7c-0.6,0-1,0.4-1,1
	s0.4,1,1,1h7.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l5-5C18.1,12.3,18.1,11.6,17.7,11.2z"
              fill="#fff"
            />
          </svg>
        </a>
      </div>
    </footer>
  );
}
