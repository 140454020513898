import React from "react";
import ResumeMain from "../components/ResumeMain";
import Footer from "../components/Footer";

export default function Resume() {
  return (
    <>
      <ResumeMain />
      <Footer />
    </>
  );
}
