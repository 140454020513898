import React from "react";
import Footer from "../components/Footer";
import "../sass/ResumeMain.scss";
import "../sass/Work.scss";

export default function Work({ content }) {
  return (
    <div>
      <div className="resume work">
        <div className="background"></div>
        <div className="content">
          <a href="#" className="arrow" onClick={() => window.history.back()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              viewBox="0 0 24 24"
              className="reversed"
            >
              <path
                d="M17.7,11.2C17.7,11.2,17.7,11.2,17.7,11.2l-5-5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.3,3.3H7c-0.6,0-1,0.4-1,1
	s0.4,1,1,1h7.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l5-5C18.1,12.3,18.1,11.6,17.7,11.2z"
              />
            </svg>
            Back
          </a>
          <h1>{content.title}</h1>
          <h3>
            at{" "}
            <a href={content.website} target="_blank" rel="noreferrer">
              {content.company}
            </a>{" "}
            • from {content.startDate} to {content.endDate} • in{" "}
            {content.location}
          </h3>
          <h4>Summary</h4>
          <ul>
            {content.summary.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
